@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100% !important;
}

.title {
  font-family: "Alfa Slab One", cursive;
  text-align: center;
}
